<template>
  <div class="sample-container">
    <div class="tags-wrap">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="草稿箱" name="first"></el-tab-pane>
        <el-tab-pane label="订单列表" name="second"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="operation-wrap">
      <div class="btn-wrap">
        <el-button type="primary" size="medium">新建订单</el-button>
        <el-button size="medium">批量新建</el-button>
      </div>
      <div class="serach-wrap">
        <el-select v-model="value" placeholder="请选择状态" size="medium">
          <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option> -->
          <el-option label="全部" value="1"></el-option>
          <el-option label="审核中" value="2"></el-option>
          <el-option label="送样中" value="3"></el-option>
        </el-select>
        <el-input
          placeholder="请输入内容"
          v-model="input3"
          class="select-wrap"
          size="medium"
        >
          <el-select v-model="select" slot="prepend" placeholder="请选择">
            <el-option label="订单号" value="1"></el-option>
            <el-option label="样本号" value="2"></el-option>
            <el-option label="合同号" value="3"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>

    <div class="list-wrap">
      <ul class="title-bar-wrap">
        <li class="order-width">订单信息</li>
        <li class="testing-width">检测信息</li>
        <li class="result-width">检测结果</li>
        <li class="status-width">状态</li>
        <li class="operation-width">操作</li>
      </ul>
      <ul class="content-wrap">
        <li class="order-width">
          <ul class="info-wrap">
            <li class="title">
              <span>212202280001</span>
              <el-tooltip
                class="item"
                effect="light"
                content="点击复制订单号"
                placement="right"
              >
                <i class="el-icon-document-copy i-copy"></i>
              </el-tooltip>
            </li>
            <li class="sub-title">
              <span>2022/3/5 10:30</span>
              <el-tooltip
                class="item"
                effect="light"
                content="订单创建时间"
                placement="right"
              >
                <i class="el-icon-time i-copy"></i>
              </el-tooltip>
            </li>
          </ul>
        </li>
        <li class="testing-width">
          <ul class="info-wrap">
            <li class="title">蛋白质游离巯基含量分析</li>
            <li class="sub-title">
              <span>样本信息</span>
              <el-divider direction="vertical"></el-divider>
              <span>委托协议</span>
              <el-divider direction="vertical"></el-divider>
              <span>项目编号</span>
              <el-divider direction="vertical"></el-divider>
              <span>合同编号</span>
              <el-divider direction="vertical"></el-divider>
              <!-- 待订单加急审核通过后再显示高亮
							<el-tooltip class="item" effect="light" content="加急订单" placement="right">
								<i class="el-icon-alarm-clock i-copy" style="color: #ff9900;"></i>
							</el-tooltip>
							 -->
              <el-tooltip
                class="item"
                effect="light"
                content="加急待审核"
                placement="right"
              >
                <i
                  class="el-icon-alarm-clock i-copy"
                  style="color: #909399"
                ></i>
              </el-tooltip>
            </li>
          </ul>
        </li>
        <li class="result-width">
          <ul class="info-wrap">
            <li>
              <el-link type="default">预览</el-link>
              <el-link type="default" style="margin-left: 8px">确认</el-link>
            </li>
            <li class="sub-title">
              <!-- <el-link type="default">下载</el-link> -->
              <el-tooltip
                class="item"
                effect="light"
                content="完成确认后方可下载"
                placement="right"
              >
                <span style="color: #c0c4cc">下载</span>
              </el-tooltip>

              <!-- ASG业务需要分析和显示到期时间
							<el-link type="default" style="margin-left: 8px;">分析</el-link>
							<el-divider direction="vertical"></el-divider>
							<span>2022/12/31到期</span> -->
            </li>
          </ul>
        </li>
        <li class="status-width">
          <ul class="info-wrap">
            <li>
              <el-tag class="status">审核中</el-tag>
            </li>
            <!-- <li><el-tag type="danger" class="status">已驳回待提交</el-tag></li>
						<li><el-tag class="status">送样中</el-tag></li>
						<li><el-tag class="status">检测中</el-tag></li>
						<li><el-tag type="danger" class="status">已终止</el-tag></li>
						<li><el-tag class="status">检测已完成</el-tag></li>
						<li><el-tag type="warning" class="status">售后待受理</el-tag></li>
						<li><el-tag type="warning" class="status">售后中</el-tag></li>
						<li><el-tag class="status">报告待发放</el-tag></li>
						<li><el-tag type="success" class="status">报告已发放</el-tag></li> -->
          </ul>
        </li>
        <li class="operation-width">
          <ul class="info-wrap">
            <!-- 已驳回 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">审核备注</el-link>
							<el-link type="primary" style="margin-left: 8px;">提交</el-link>
							<el-link type="primary" style="margin-left: 8px;">删除</el-link>
						</li> -->
            <!-- 送样中 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">查看物流</el-link>
						</li> -->
            <!-- 已终止 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">审核备注</el-link>
						</li> -->
            <!-- 检测已完成 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">申请售后</el-link>
						</li> -->
            <!-- 售后待受理 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">审核备注</el-link>
						</li> -->
            <!-- 售后中 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">审核备注</el-link>
						</li> -->
            <!-- 报告待发放 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">审核备注</el-link>
						</li> -->
            <!-- 报告已发放 -->
            <!-- <li class="operation-wrap">
							<el-link type="primary">查看物流</el-link>
						</li> -->
            <li class="operation-wrap">
              <el-link type="primary">详情</el-link>
              <el-link type="primary" style="margin-left: 8px">复制</el-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="elPagination-wrap"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "second",
      select: 1,
      input3: "",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-container {
  background-color: #fff;
  margin: 22px 22px;
  padding: 0 16px;
  border-radius: 2px;
  min-height: calc(100vh - 52px - 56px);

  .operation-wrap {
    // margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .serach-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;

      .select-wrap {
        margin-left: 10px;
      }
    }
  }

  .list-wrap {
    margin-top: 16px;

    .title-bar-wrap {
      height: 48px;
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: $fontColor_gray;
      border-bottom: 1px solid $theme_bd_color;
      background-color: $theme_bg_color;
    }

    .order-width {
      width: 20%;
    }

    .testing-width {
      width: 30%;
    }

    .result-width {
      width: 20%;
    }

    .status-width {
      width: 10%;
    }

    .operation-width {
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }

    .content-wrap {
      height: 78px;
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #606266;
      border-bottom: 1px solid #f0f0f0;

      .info-wrap {
        display: flex;
        flex-direction: column;
        line-height: 28px;

        .i-copy {
          margin-left: 4px;
        }

        .title {
          color: #303133;
        }

        .sub-title {
          color: #909399;
        }

        .status {
          display: flex;
          justify-content: center;
          width: 96px;
          border-radius: 30px;
        }

        .operation-wrap {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
